body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 14px;
}

/* ----- TABLES ----- */

.tanstack {
  width: 100%;
  border-spacing: 0px;
  border: 1px solid #eaecf0;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.tanstack thead {
}

/* case head */
.tanstack  th {
  background-color: #f9fafb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 12px 24px 12px 24px;
  text-align: left;
  white-space: nowrap;
  color: #656565;
}

tbody {
}

.tanstack  tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 12px;
}

.tanstack  tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 12px;
}

/* case body */
.tanstack  td {
  box-sizing: border-box;
  background: white;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid #eaecf0;
  padding: 12px 24px 12px 24px;
}

/* line */
.tanstack  tr {
  /* cursor: pointer; */
  color: #333333;
}
